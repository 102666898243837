<template>
  <v-card class="mb-4 mt-4 pa-3">
    <v-row justify="start">
      <v-col cols="12" md="2" class="mt-2">
        <BaseFilter
          prepend-inner-icon="mdi-file-table-box-multiple-outline"
          clearable
          single-line
          label="Segmento"
          item-text="descricao"
          item-value="id_band"
          service="sistemaService.tipoBandeiras"
          v-model="filters.id_band"
          :filters="{ id_band: '2,4' }"
          :isCompany="true"
          @change="handleFilter()"
        />
      </v-col>
      <v-col cols="12" md="2" class="mt-2">
        <BaseFilter
          prepend-inner-icon="mdi-domain"
          clearable
          single-line
          label="Empresa"
          item-text="apelido"
          item-value="id_empresa"
          service="sistemaService.empresa"
          :filters="{
            id_band: filters.id_band,
            calcula_comissao: 'S'
          }"
          v-model="filters.id_empresa"
          :isCompany="true"
          @change="handleFilter()"
        />
      </v-col>
      <v-col cols="12" md="2" class="mt-2">
        <BaseFilter
          label="Departamento"
          v-model="filters.id_depto"
          service="comissaoService.departamentos"
          item-text="departamento"
          item-value="id_depto"
          single-line
          prepend-inner-icon="mdi-toolbox"
          clearable
          :multiple="false"
          @change="handleFilter()"
        />
      </v-col>
      <v-col cols="12" md="3" class="mt-2">
        <BaseFilter
          prepend-inner-icon="mdi-account"
          clearable
          single-line
          label="Função"
          item-text="descricao"
          item-value="id_funcao"
          service="sistemaService.funcoes"
          :filters="{ id_band: filters.id_band, ativo: 'S' }"
          v-model="filters.id_funcao"
          :hasCode="true"
          @change="handleFilter()"
        />
      </v-col>
      <v-col cols="12" md="3" class="mt-2">
        <BaseFilter
          prepend-inner-icon="mdi-account"
          clearable
          single-line
          label="Colaboradores"
          item-text="colaborador"
          item-value="id_colaborador"
          service="sistemaService.colaboradorEmpresa"
          :filters="{
            id_empresa: filters.id_empresa,
            id_funcao: filters.id_funcao,
            id_band: filters.id_band,
            ativo: 'S'
          }"
          v-model="filters.id_colaborador"
          :hasCode="true"
          @change="handleFilter()"
        >
        </BaseFilter>
      </v-col>
      <!-- new line -->
      <v-col cols="12" md="3" class="mt-2">
        <BaseFilter
          label="Indicador"
          service="sistemaService.indicadores"
          v-model="filters.id_indicador"
          item-text="indicador"
          item-value="id_indicador"
          prepend-inner-icon="mdi-finance"
          clearable
          chips
          single-line
          :hasCode="true"
          :multiple="false"
          :filters="{ ativo: 'S' }"
          @change="handleFilter()"
        />
      </v-col>
      <v-col
        v-if="currentRoute == routes.supervisorEquipe"
        class="mt-2"
        md="3"
        sm="12"
      >
        <BaseFilter
          :disabled="currentRoute != routes.supervisorEquipe"
          v-model="filters.id_equipe"
          label="Equipe"
          item-text="nome_equipe"
          single-line
          item-value="id_equipe"
          clearable
          prepend-inner-icon="mdi-account-multiple"
          service="comissaoService.tipoEquipe"
          :filters="{
            id_band: filters.id_band,
            id_empresa: filters.id_empresa,
            ativo: 'S'
          }"
          @change="handleFilter()"
        />
      </v-col>
      <v-col
        v-if="
          currentRoute == routes.supervisorEquipe ||
            currentRoute == routes.deptoComercial
        "
        cols="12"
        sm="2"
        class="mt-2"
      >
        <BaseFilter
          :disabled="
            currentRoute != routes.supervisorEquipe &&
              currentRoute != routes.deptoComercial
          "
          service="comissaoService.tipoDados"
          chips
          label="Tipo Dado"
          item-text="descricao"
          item-value="id_tipo_dado"
          v-model="filters.id_tipo_dado"
          clearable
          single-line
          prepend-inner-icon="mdi-calendar-end"
          :filters="{ ativa_campanha: 'N' }"
          @change="handleFilter()"
        />
      </v-col>
      <v-col cols="12" md="2">
        <DatePickerMonth @date="assignDateFilter($event)" />
      </v-col>

      <v-col
        v-if="currentRoute == routes.deptoComercial"
        class="mt-2"
        md="2"
        sm="12"
      >
        <BaseFilter
          :disabled="currentRoute != routes.deptoComercial"
          service="comissaoService.statusConsorcio"
          chips
          label="Parcela"
          item-text="status"
          item-value="id_status"
          v-model="filters.parcela"
          clearable
          single-line
          prepend-inner-icon="mdi-abacus"
          @change="handleFilter()"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import BaseFilter from "@/components/shared/BaseSelectService";
import DatePickerMonth from "@/components/shared/DatePickerMonth";

export default {
  name: "FiltroRelatorios",
  components: {
    BaseFilter,
    DatePickerMonth
  },

  data() {
    return {
      currentRoute: "",
      filters: {},
      routes: {
        deptoComercial: "depto-comercial",
        supervisorEquipe: "supervisor-equipe"
      }
    };
  },

  methods: {
    handleFilter() {
      this.$emit("selectedFilters", this.jasperFilters());
    },
    assignDateFilter(date) {
      this.filters["ano_ref"] = date?.ano_ref;
      this.filters["mes_ref"] = date?.mes_ref;
      this.handleFilter();
    },
    jasperFilters() {
      return {
        in_id_band: this.filters["id_band"],
        in_id_empresa: this.filters["id_empresa"],
        in_id_colaborador: this.filters["id_colaborador"],
        in_id_depto: this.filters["id_depto"],
        in_mes_ref: this.filters["mes_ref"],
        in_ano_ref: this.filters["ano_ref"],
        // in_ativo: this.collaborator["status"],
        in_id_funcao: this.filters["id_funcao"],
        in_tipo_dado: this.filters["id_tipo_dado"],
        in_parcela: this.filters["parcela"],
        in_id_indicador: this.filters["id_indicador"]
      };
    },
    getFiltersFromReport() {
      this.currentRoute = this.$router.history.current.path.split(
        "relatorios-"
      )[1];
    }
  },
  mounted() {
    this.getFiltersFromReport();
  }
};

//DeptoComercial:[segmento, empresa, colaborador, função, depto, mes/ano, parcelas]
//DeptoAdm:[Empresa, mes/ano]
//Colaboradores: [segmento, empresa, colaborador, função, depto, mesAdm/anoAdm]
//SupervisorEquipe: [segmento, empresa, colaborador, função, depto, mes/ano, equipe, tipo de dado, ]
//Comissoes: [segmento, empresa, colaborador, função, depto, mes/ano ]

//not commom: parcelas, equipe, tipo de dado,
</script>

<style scoped>
.adjust {
  margin-top: -10px;
  margin-bottom: -10px;
}
</style>
