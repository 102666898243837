<template>
  <div>
    <Loader :active="loading" message="Gerando relatório..." />
    <v-expansion-panels focusable>
      <v-expansion-panel v-for="(item, i) in list" :key="i">
        <v-expansion-panel-header>
          <v-icon class="mr-2">
            {{ item.icon }}
          </v-icon>
          {{ item.title }}
        </v-expansion-panel-header>
        <v-expansion-panel-content class="adjust">
          <v-list dense>
            <v-list-item-group v-model="selectedItem" color="primary">
              <v-list-item
                v-for="(subItem, j) in item.reportList"
                :key="j"
                v-can-access="subItem.permission"
              >
                <v-list-item-content>
                  <v-list-item-title
                    class="textStyle"
                    v-text="subItem.text"
                    @click="fetchReport(subItem.service)"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import jasperService from "@/services/http/jasperService";
import Loader from "@/components/shared/LoaderCometa";

export default {
  components: {
    Loader
  },
  props: {
    filters: {
      type: Object
    },
    route: {
      type: String
    },
    list: {
      type: Array
    }
  },

  data() {
    return {
      loading: false,
      selectedItem: ""
    };
  },

  methods: {
    generatePdf(data) {
      var blob = new Blob([data], { type: "application/pdf" });
      var blobURL = URL.createObjectURL(blob);
      window.open(blobURL);
    },
    async fetchReport(service) {
      this.loading = true;
      try {
        let { data } = await jasperService()
          .comissao()
          [service]()
          .show({ ...this.filters });
        this.loading = false;
        this.generatePdf(data);
      } catch (error) {
        this.loading = false;
        this.$notify({
          type: "error",
          text: "Erro ao gerar relatótio. Contate o suporte"
        });
      }
    }
  }
};
</script>

<style scoped>
.adjust >>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
.textStyle {
  color: #000;
  font-weight: 400 !important;
}

.v-expansion-panel-header > *:not(.v-expansion-panel-header__icon) {
  flex: 0 !important;
}
</style>
