var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-4 mt-4 pa-3"},[_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12","md":"2"}},[_c('BaseFilter',{attrs:{"prepend-inner-icon":"mdi-file-table-box-multiple-outline","clearable":"","single-line":"","label":"Segmento","item-text":"descricao","item-value":"id_band","service":"sistemaService.tipoBandeiras","filters":{ id_band: '2,4' },"isCompany":true},on:{"change":function($event){return _vm.handleFilter()}},model:{value:(_vm.filters.id_band),callback:function ($$v) {_vm.$set(_vm.filters, "id_band", $$v)},expression:"filters.id_band"}})],1),_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12","md":"2"}},[_c('BaseFilter',{attrs:{"prepend-inner-icon":"mdi-domain","clearable":"","single-line":"","label":"Empresa","item-text":"apelido","item-value":"id_empresa","service":"sistemaService.empresa","filters":{
          id_band: _vm.filters.id_band,
          calcula_comissao: 'S'
        },"isCompany":true},on:{"change":function($event){return _vm.handleFilter()}},model:{value:(_vm.filters.id_empresa),callback:function ($$v) {_vm.$set(_vm.filters, "id_empresa", $$v)},expression:"filters.id_empresa"}})],1),_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12","md":"2"}},[_c('BaseFilter',{attrs:{"label":"Departamento","service":"comissaoService.departamentos","item-text":"departamento","item-value":"id_depto","single-line":"","prepend-inner-icon":"mdi-toolbox","clearable":"","multiple":false},on:{"change":function($event){return _vm.handleFilter()}},model:{value:(_vm.filters.id_depto),callback:function ($$v) {_vm.$set(_vm.filters, "id_depto", $$v)},expression:"filters.id_depto"}})],1),_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12","md":"3"}},[_c('BaseFilter',{attrs:{"prepend-inner-icon":"mdi-account","clearable":"","single-line":"","label":"Função","item-text":"descricao","item-value":"id_funcao","service":"sistemaService.funcoes","filters":{ id_band: _vm.filters.id_band, ativo: 'S' },"hasCode":true},on:{"change":function($event){return _vm.handleFilter()}},model:{value:(_vm.filters.id_funcao),callback:function ($$v) {_vm.$set(_vm.filters, "id_funcao", $$v)},expression:"filters.id_funcao"}})],1),_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12","md":"3"}},[_c('BaseFilter',{attrs:{"prepend-inner-icon":"mdi-account","clearable":"","single-line":"","label":"Colaboradores","item-text":"colaborador","item-value":"id_colaborador","service":"sistemaService.colaboradorEmpresa","filters":{
          id_empresa: _vm.filters.id_empresa,
          id_funcao: _vm.filters.id_funcao,
          id_band: _vm.filters.id_band,
          ativo: 'S'
        },"hasCode":true},on:{"change":function($event){return _vm.handleFilter()}},model:{value:(_vm.filters.id_colaborador),callback:function ($$v) {_vm.$set(_vm.filters, "id_colaborador", $$v)},expression:"filters.id_colaborador"}})],1),_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12","md":"3"}},[_c('BaseFilter',{attrs:{"label":"Indicador","service":"sistemaService.indicadores","item-text":"indicador","item-value":"id_indicador","prepend-inner-icon":"mdi-finance","clearable":"","chips":"","single-line":"","hasCode":true,"multiple":false,"filters":{ ativo: 'S' }},on:{"change":function($event){return _vm.handleFilter()}},model:{value:(_vm.filters.id_indicador),callback:function ($$v) {_vm.$set(_vm.filters, "id_indicador", $$v)},expression:"filters.id_indicador"}})],1),(_vm.currentRoute == _vm.routes.supervisorEquipe)?_c('v-col',{staticClass:"mt-2",attrs:{"md":"3","sm":"12"}},[_c('BaseFilter',{attrs:{"disabled":_vm.currentRoute != _vm.routes.supervisorEquipe,"label":"Equipe","item-text":"nome_equipe","single-line":"","item-value":"id_equipe","clearable":"","prepend-inner-icon":"mdi-account-multiple","service":"comissaoService.tipoEquipe","filters":{
          id_band: _vm.filters.id_band,
          id_empresa: _vm.filters.id_empresa,
          ativo: 'S'
        }},on:{"change":function($event){return _vm.handleFilter()}},model:{value:(_vm.filters.id_equipe),callback:function ($$v) {_vm.$set(_vm.filters, "id_equipe", $$v)},expression:"filters.id_equipe"}})],1):_vm._e(),(
        _vm.currentRoute == _vm.routes.supervisorEquipe ||
          _vm.currentRoute == _vm.routes.deptoComercial
      )?_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12","sm":"2"}},[_c('BaseFilter',{attrs:{"disabled":_vm.currentRoute != _vm.routes.supervisorEquipe &&
            _vm.currentRoute != _vm.routes.deptoComercial,"service":"comissaoService.tipoDados","chips":"","label":"Tipo Dado","item-text":"descricao","item-value":"id_tipo_dado","clearable":"","single-line":"","prepend-inner-icon":"mdi-calendar-end","filters":{ ativa_campanha: 'N' }},on:{"change":function($event){return _vm.handleFilter()}},model:{value:(_vm.filters.id_tipo_dado),callback:function ($$v) {_vm.$set(_vm.filters, "id_tipo_dado", $$v)},expression:"filters.id_tipo_dado"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('DatePickerMonth',{on:{"date":function($event){return _vm.assignDateFilter($event)}}})],1),(_vm.currentRoute == _vm.routes.deptoComercial)?_c('v-col',{staticClass:"mt-2",attrs:{"md":"2","sm":"12"}},[_c('BaseFilter',{attrs:{"disabled":_vm.currentRoute != _vm.routes.deptoComercial,"service":"comissaoService.statusConsorcio","chips":"","label":"Parcela","item-text":"status","item-value":"id_status","clearable":"","single-line":"","prepend-inner-icon":"mdi-abacus"},on:{"change":function($event){return _vm.handleFilter()}},model:{value:(_vm.filters.parcela),callback:function ($$v) {_vm.$set(_vm.filters, "parcela", $$v)},expression:"filters.parcela"}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }